import React from 'react';

const Skills = () => {

  const listTechs = [
    "fab fa-java",
    "fab fa-js-square",
    "fab fa-html5",
    "fab fa-css3-alt",
    "fab fa-react",
    "fab fa-angular",
    "fab fa-node-js",
    "fab fa-sass",
    "fab fa-less",
    "fab fa-gulp",
    "fab fa-grunt",
    "fab fa-npm",
    "fab fa-yarn",
    "fab fa-aws",
    "fab fa-android",
    "fab fa-git-alt",
    "fab fa-linux",
    "fab fa-docker",
    "fab fa-slack",
    "fas fa-database",
    //"fab fa-php",
    //"fab fa-python",
    //"fab fa-jenkins",
  ];

  const listWorkflow = [
    "Agile Development & Scrum",    
    "Cross Browser Testing & Debugging",
    "Cross Functional Teams",
    "Mobile-First, Responsive Design",
  ];

  return (
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="skills"
    >
      <div className="w-100">
        <h2 className="mb-5">Skills</h2>

        <div className="subheading mb-3">
          Programming Languages &amp; Tools
          </div>
        <ul className="list-inline dev-icons">
          {
            listTechs.map((item, index) =>
              <li className="list-inline-item" key={index}>
                <i className={item}></i>
              </li>
            )
          }
        </ul>

        <div className="subheading mb-3">Workflow</div>
        <ul className="fa-ul mb-0">
          {
            listWorkflow.map((item, index) =>
              <li key={index}>
                <i className="fa-li fa fa-check"></i>
                {item}
              </li>
            )
          }
        </ul>
      </div>
    </section>
  );
}

export default Skills;