import React from 'react';
import badgeJava from '../assets/img/badge-java-oracle.png';
import badgeJavaScript from '../assets/img/badge-javascript-microsoft.png';
import badgeScrum from '../assets/img/badge-scrum-certiprof.png';
import badgeKanban from '../assets/img/badge-kanban-certiprof.png';
import badgeDevops from '../assets/img/badge-devops-certiprof.png';

export const useBadgeCertification = () => {

  return [ 
      badgeJava, 
      badgeJavaScript, 
      badgeScrum, 
      badgeKanban, 
      badgeDevops 
    ];
}
