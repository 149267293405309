import React from 'react';

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
//import config from '../../config';
import About from '../components/sections/About';
import Experience from '../components/sections/Experience';
import Division from '../components/sections/Division';
import Education from '../components/sections/Education';
import Skill from '../components/sections/Skills';
//import Interests from '../components/sections/Interests';
import Certifications from '../components/sections/Certifications';

const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <About />
      <Division />
      <Experience />
      <Division />
      <Education />
      <Division />
      <Skill />
      <Division />
      <Certifications />
    </div>
  </Layout>
);

export default IndexPage;
