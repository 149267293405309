module.exports = {
  siteTitle: 'Bruno Lima - Resume', // <title>
  manifestName: 'Bruno Lima - Resume',
  manifestShortName: 'Bruno Lima', // max 12 characters
  manifestStartUrl: '/index.html',
  manifestBackgroundColor: '#114e90',
  manifestThemeColor: '#114e90',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  //pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'Bruno',
  lastName: 'Lima',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/in/brunorlima/',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/brunorelima',
    },
    /*
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/brunorelima',
    },
    */
  ],
  email: 'brunorelima@gmail.com',
  role: 'Software Engineer',
  address: 'Brazil',
};
