import React from 'react';

const Experience = () => {

  const list = [
    {
      role: "Senior Software Development Engineer",
      company: "Freelance (Contract)",
      local: "Remote",
      date: "Jun 2020 - Present",
      desc: ""
    },
    {
      role: "Software Engineer",
      company: "IFTM",
      local: "Uberaba, Brazil",
      date: "Jun 2013 - Present",
      desc: "Responsible for development and maintenance of the institutional ERP"
    },    
    {
      role: "Software Developer",
      company: "Codiub",
      local: "Uberaba, Brazil",
      date: "Jul 2010 - Jun 2013",
      desc: "Full lifecycle development of the application, from planning, requirements gathering, development, testing"
    },
    {
      role: "Developer / IT",
      company: "Funepu",
      local: "Uberaba, Brazil",
      date: "Sep 2009 - Jun 2010",
      desc: "Programmer/Technical Support Analyst"
    },
  ]

  return (
    <section
      className="resume-section p-3 p-lg-5 d-flex justify-content-center"
      id="experience"
    >
      <div className="w-100">
        <h2 className="mb-5">Experience</h2>

        {
          list.map((item,index)=>
            <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5" key={index}>
              <div className="resume-content">
                <h3 className="mb-0">{item.role}</h3>
                <div className="subheading mb-3">{item.company} - {item.local}</div>
              </div>
              <div className="resume-date text-md-right">
                <span className="text-primary">{item.date}</span>
              </div>
            </div>
          )
        }

      </div>
    </section>
  );
}

export default Experience;