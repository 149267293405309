import React from 'react';

const Education = () => {
  const list = [
    {
      school: "UFTM",
      local: "Uberaba, Brazil",
      course: "Master's degree in Technological Innovation",
      date: "2018 - 2020",
    },
    {
      school: "ESAB",
      local: "Vila Velha, Brazil",
      course: "Post Graduate Diploma in Systems Engineering",
      date: "2013 - 2014",
    },
    {
      school: "IFTM",
      local: "Uberaba, Brazil",
      course: "Technology in Analysis and Systems Development",
      date: "2007 - 2010",
    },
    /*
    {
      school: "IFTM",
      local: "Uberaba, Brazil",
      course: "Computer Technician",
      date: "2004 - 2005",
    },
    */
  ];

  return (
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="education"
    >
      <div className="w-100">
        <h2 className="mb-5">Education</h2>

        {
          list.map((item, index)=>
            <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5" key={index}>
              <div className="resume-content">
                <h3 className="mb-0">{item.course}</h3>
                <div className="subheading mb-3">{item.school  + " - " + item.local}</div>
              </div>
              <div className="resume-date text-md-right">
                <span className="text-primary">{item.date}</span>
              </div>
            </div>)
        }

      </div>
    
    </section>
  );
}

export default Education;