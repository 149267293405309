import React from 'react';
import { useBadgeCertification } from '../../hooks/use-badge-certification'

const Certifications = () => {
  const [ badgeJava, badgeJavaScript, badgeScrum, badgeKanban, badgeDevops ] = useBadgeCertification();

  const list = [
    {
      name: "Java SE 7 Programmer (OCA)",
      issued: "Oracle",
      icon: badgeJava,
      link: "https://www.youracclaim.com/badges/93854784-b31f-4d47-bb50-ebc326145c32",
    },
    {
      name: "Programming in HTML5 with JavaScript and CSS3 Specialist",
      issued: "Microsoft",
      icon: badgeJavaScript,
      link: "https://www.youracclaim.com/badges/6aa7b5d8-f430-46b1-9d78-e99b04eb7821",
    },
    {
      name: "Scrum Foundation Professional Certificate (SFPC)",
      issued: "CertiProf",
      icon: badgeScrum,
      link: "https://www.youracclaim.com/badges/8891d46b-5c17-49b8-a3f1-f9ad46aaa31b",
    },
    {
      name: "Kanban Foundation (KIKF)",
      issued: "CertiProf",
      icon: badgeKanban,
      link: "https://www.youracclaim.com/badges/7e6d9381-641d-419c-9992-8d4a9c123632",
    },
    {
      name: "DevOps Essentials Professional Certificate (DEPC)",
      issued: "CertiProf",
      icon: badgeDevops,
      link: null,
    },
  ]

  return (
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="certifications"
    >
      <div className="w-100">
        <h2 className="mb-5">Certifications</h2>
        <ul className="fa-ul mb-0">
          {
            list.map((item, index) =>
              <li className="certification-item" key={index}>
                <img src={item.icon} className="certification-badge" alt={"certification badge of " + item.name} />
                <div>
                  {item.link ?
                    <span>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <h3>{item.name}</h3>
                      </a>
                      <span className="subheading">{item.issued}</span>
                    </span>
                    :
                    <span>
                      <h3>{item.name}</h3>
                      <span className="subheading">{item.issued}</span>
                    </span>
                  }
                </div>
              </li>
            )
          }
        </ul>
      </div>
    </section>
  );
}

export default Certifications;