import React from 'react';
import config from '../../../config';

const About = () => {
  return (
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="about"
    >
      <div className="w-100">
        <h1 className="mb-0">
          {config.firstName}
          <span className="text-primary">{config.lastName}</span>
        </h1>
        <div className="subheading mb-5">
          {config.role}
          <span> - </span>
          <a href={`mailto:${config.email}`}>{config.email}</a>
        </div>
        <p className="lead mb-5">
          I’m an experienced and passionate full stack developer with over 10 years of experience in software engineering.
          Skilled in all phases of the software development life-cycle.
          For me, good software means a better experience for the end user and also easy maintenance and extension.
          </p>

        <div className="social-icons">
          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <a key={url} href={url}>
                <i className={`fab ${icon}`}></i>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default About;